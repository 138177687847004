import { createLazyLoadWrapper } from 'Utils/lazyLoadWrapper'
import store from 'Store/index'

const moduleName = 'del-car'

export default createLazyLoadWrapper({
  name: moduleName,
  importComponent: () => import('./DeleteCar'),
  async createDependencies () {
    const { default: module } = await import('./store')
    store.registerModule(['ui', moduleName], module)
  },
  destroyDependencies () {
    store.unregisterModule(['ui', moduleName])
  }
})
