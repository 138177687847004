import Vue from 'vue'
import Router from 'vue-router'

// TODO: add lazy load
import AdminPanel from 'Components/AdminPanel'
import SignIn from 'Components/SignIn'

import Won from 'Components/Won'
import Analytics from 'Components/Analytics'
import Search from 'Components/Search'
import Transportation from 'Components/Transportation'
import EditProfile from 'Components/EditProfile'

import Carfax from 'Components/Carfax'
import AddCar from 'Components/AddCar'
import DelCar from 'Components/DeleteCar'

import Leads from 'Components/Leads'
import ActiveTrades from 'Components/ActiveTrades'
import Archive from 'Components/Archive'
import Deposits from 'Components/Deposits'
import Notifications from 'Components/Notifications'
import MyTrades from 'Components/MyTrades'

import { rootGet } from 'Store/helpers/rootHelpers'
import { config } from '@/config'
import {
  LEADS_URL_PARAM_ASSIGNMENTS,
  LEADS_URL_PARAM_LEAD_TABS,
  LEADS_URL_PARAM_TABS
} from 'Constants/leadUrlParams'
import { USER_CLAIMS } from 'Constants/userClaims'

import { ACTIVE_TRADES_URL_PARAM_TABS } from 'Constants/activeTradesUrlParams'
import { DEPOSITS_URL_PARAM_TABS } from 'Constants/depositsUrlParams'
import { NOTIFICATIONS_URL_PARAM_TABS } from 'Constants/notificationsUrlParams'
import { MY_TRADES_URL_PARAM_TABS } from 'Constants/myTradesUrlParams'

import { userGetters } from 'Store/entities/User/types'
import { can } from 'Utils/userHelpers'
import { WON_URL_PARAMS_TABS } from 'Constants/wonUrlParams'
import { showLeadBeforeEnter } from './showLeadBeforeEnter'

import Users from 'Components/Users'
import { USERS_URL_PARAM_TABS } from 'Constants/usersUrlParams'
import { USER_CARD_TABS } from 'Components/Users/constants'

import CopartExtensionDealers from 'Components/Extension/Copart/Dealers'
import CopartExtensionAccounts from 'Components/Extension/Copart/Accounts'
import CopartExtensionFees from 'Components/Extension/Copart/Fees'
import CopartExtensionWon from 'Components/Extension/Copart/Won'
import CopartExtensionAnalytics from 'Components/Extension/Copart/Analytics'
import CopartExtensionClaims from 'Components/Extension/Copart/Claims'
import CopartExtensionNotifications from 'Components/Extension/Copart/Notifications'

import ImpactExtensionDealers from 'Components/Extension/Impact/Dealers'
import ImpactExtensionAccounts from 'Components/Extension/Impact/Accounts'
import ImpactExtensionWon from 'Components/Extension/Impact/Won'

import { FEES_URL_PARAM_TABS } from 'Constants/feesUrlParams'
import {
  COPART_WON_URL_PARAM_TABS,
  IMPACT_WON_URL_PARAM_TABS
} from 'Constants/extensionWonUrlParams'

Vue.use(Router)

const routes = [
  {
    path: '/',
    name: 'adminPanel',
    component: AdminPanel.rootComponent,
    redirect: (to) => {
      if (!isLoggedIn()) return { name: 'signIn' }

      if (isCarfaxReseller()) {
        return { name: 'carfax' }
      }
      if (can(USER_CLAIMS.LEADS)) {
        return { name: 'leads' }
      }
      if (can(USER_CLAIMS.ACTIVE_TRADES)) {
        return { name: 'activeTrades.entry' }
      }
      if (can(USER_CLAIMS.DEPOSITS_VIEW)) {
        return { name: 'deposits.entry' }
      }
      if (can(USER_CLAIMS.EXTENSION_DEALERS)) {
        return { name: 'extension.copart' }
      }

      return { name: 'won' }
    },
    children: [
      {
        path: [
          `leads`,
          `:assignment(${toUrlParams(LEADS_URL_PARAM_ASSIGNMENTS)})?`,
          `:tab(${toUrlParams(LEADS_URL_PARAM_TABS)})`,
          ':leadId?',
          `:leadTab(${toUrlParams(LEADS_URL_PARAM_LEAD_TABS)})?`
        ].join('/'),
        name: 'leads',
        component: Leads,
        meta: {
          isLoggedInOnly: true,
          claim: USER_CLAIMS.LEADS
        }
      },
      {
        path: 'l/:leadId',
        name: 'showLead',
        beforeEnter: showLeadBeforeEnter,
        meta: {
          isLoggedInOnly: true,
          claim: USER_CLAIMS.LEADS
        }
      },
      {
        path: 'services',
        name: 'services',
        redirect: () => {
          if (can(USER_CLAIMS.CARFAX)) {
            return { name: 'carfax' }
          }

          if (can(USER_CLAIMS.W8_TRANSPORTATION)) {
            return { name: 'transportation' }
          }
        },
        children: [
          {
            path: 'carfax',
            name: 'carfax',
            meta: {
              isLoggedInOnly: true,
              claim: USER_CLAIMS.CARFAX
            },
            component: Carfax
          },
          {
            path: 'transportation',
            name: 'transportation',
            meta: {
              isLoggedInOnly: true,
              claim: USER_CLAIMS.W8_TRANSPORTATION,
              feature: 'TRANSPORTATION_FEES'
            },
            component: Transportation
          }
        ],
        component: { render: (h) => h('router-view') }
      },
      {
        path: 'platform-sales',
        name: 'platformSales',
        redirect: () => {
          if (can(USER_CLAIMS.MY_TRADES)) {
            return { name: 'myTrades.entry' }
          }

          if (can(USER_CLAIMS.UPLOAD)) {
            return { name: 'addCar.entry' }
          }

          if (can(USER_CLAIMS.UPLOAD)) {
            return { name: 'delCar.entry' }
          }
        },
        children: [
          {
            path: 'add-car',
            name: 'addCar.entry',
            component: AddCar,
            redirect: {
              name: 'addCar',
              params: { auctionType: 'direct-sale' }
            },
            children: [
              {
                path: ':auctionType(auction|direct-sale)',
                name: 'addCar',
                meta: {
                  isLoggedInOnly: true,
                  claim: USER_CLAIMS.UPLOAD
                }
              }
            ],
            meta: {
              isLoggedInOnly: true,
              claim: USER_CLAIMS.UPLOAD
            }
          },
          {
            path: 'del-car',
            name: 'delCar.entry',
            component: DelCar,
            redirect: {
              name: 'delCar',
              params: { auctionType: 'direct-sale' }
            },
            children: [
              {
                path: ':auctionType(auction|direct-sale)',
                name: 'delCar',
                meta: {
                  isLoggedInOnly: true,
                  claim: USER_CLAIMS.MY_TRADES
                }
              }
            ],
            meta: {
              isLoggedInOnly: true,
              claim: USER_CLAIMS.MY_TRADES
            }
          },
          {
            path: 'my-trades',
            name: 'myTrades.entry',
            redirect: () => {
              const params = { tab: MY_TRADES_URL_PARAM_TABS.active }
              return { name: 'myTrades', params }
            },
            component: MyTrades,
            meta: {
              isLoggedInOnly: true,
              claim: USER_CLAIMS.MY_TRADES
            },
            children: [
              {
                path: `:tab(${toUrlParams(MY_TRADES_URL_PARAM_TABS)})`,
                name: 'myTrades',
                meta: {
                  isLoggedInOnly: true,
                  claim: USER_CLAIMS.MY_TRADES
                }
              }
            ]
          }
        ],
        component: { render: (h) => h('router-view') }
      },
      {
        path: 'analytics',
        name: 'analytics',
        component: Analytics,
        meta: {
          claim: USER_CLAIMS.ANALYTICS,
          isLoggedInOnly: true
        }
      },
      {
        path: 'active-trades',
        name: 'activeTrades.entry',
        redirect: () => {
          const params = { tab: ACTIVE_TRADES_URL_PARAM_TABS.playing }
          return { name: 'activeTrades', params }
        },
        component: ActiveTrades,
        meta: {
          isLoggedInOnly: true,
          claim: USER_CLAIMS.ACTIVE_TRADES
        },
        children: [
          {
            path: `:tab(${toUrlParams(ACTIVE_TRADES_URL_PARAM_TABS)})`,
            name: 'activeTrades',
            meta: {
              isLoggedInOnly: true,
              claim: USER_CLAIMS.ACTIVE_TRADES
            }
          }
        ]
      },
      {
        path: 'deposits',
        name: 'deposits.entry',
        redirect: () => {
          const params = { tab: DEPOSITS_URL_PARAM_TABS.holding }
          return { name: 'deposits', params }
        },
        component: Deposits,
        meta: {
          isLoggedInOnly: true,
          claim: USER_CLAIMS.DEPOSITS_VIEW
        },
        children: [
          {
            path: `:tab(${toUrlParams(DEPOSITS_URL_PARAM_TABS)})`,
            name: 'deposits',
            meta: {
              isLoggedInOnly: true,
              claim: USER_CLAIMS.DEPOSITS_VIEW
            }
          }
        ]
      },
      {
        path: 'notifications',
        name: 'notifications.entry',
        redirect: () => {
          const params = { tab: NOTIFICATIONS_URL_PARAM_TABS.urgent }
          return { name: 'notifications', params }
        },
        component: Notifications,
        meta: {
          isLoggedInOnly: true,
          claim: USER_CLAIMS.NOTIFICATIONS
        },
        children: [
          {
            path: `:tab(${toUrlParams(NOTIFICATIONS_URL_PARAM_TABS)})`,
            name: 'notifications',
            meta: {
              isLoggedInOnly: true,
              claim: USER_CLAIMS.NOTIFICATIONS
            }
          }
        ]
      },
      {
        path: 'won',
        name: 'won.entry',
        component: Won,
        meta: {
          isLoggedInOnly: true,
          claim: USER_CLAIMS.WON
        },
        redirect: () => ({
          name: 'won',
          params: { tab: WON_URL_PARAMS_TABS.invoice }
        }),
        children: [
          {
            path: `:tab(${toUrlParams(WON_URL_PARAMS_TABS)})`,
            name: 'won',
            meta: {
              isLoggedInOnly: true,
              claim: USER_CLAIMS.WON
            }
          }
        ]
      },
      {
        path: 'search',
        name: 'search',
        meta: { isLoggedInOnly: true },
        component: Search
      },
      {
        path: '/edit-profile',
        name: 'editProfile',
        meta: {
          isLoggedInOnly: true,
          claim: USER_CLAIMS.EDIT_PROFILE
        },
        component: EditProfile
      },
      {
        path: '/archive',
        name: 'archive.entry',
        meta: {
          isLoggedInOnly: true,
          claim: USER_CLAIMS.ARCHIVE
        },
        component: Archive
      },
      {
        path: 'users',
        name: 'users.entry',
        redirect: () => {
          const params = { tab: USERS_URL_PARAM_TABS.regular }
          return { name: 'users', params }
        },
        component: Users,
        meta: {
          isLoggedInOnly: true,
          claim: USER_CLAIMS.USER_MANAGEMENT
        },
        children: [
          {
            path: [
              `:tab(${toUrlParams(USERS_URL_PARAM_TABS)})`,
              ':userId?',
              `:userTab(${toUrlParams(USER_CARD_TABS)})?`
            ].join('/'),
            name: 'users',
            meta: {
              isLoggedInOnly: true,
              claim: USER_CLAIMS.USER_MANAGEMENT
            }
          }
        ]
      },
      {
        path: 'extension/copart',
        name: 'extension.copart',
        component: { render: (h) => h('router-view') },
        redirect: () => {
          if (can(USER_CLAIMS.EXTENSION_DEALERS)) {
            return { name: 'extension.copart.dealers.entry' }
          }

          if (can(USER_CLAIMS.EXTENSION_ACCOUNTS)) {
            return { name: 'extension.copart.accounts.entry' }
          }

          if (can(USER_CLAIMS.EXTENSION_FEES)) {
            return { name: 'extension.copart.fees.entry' }
          }
        },
        children: [
          {
            path: 'fees',
            name: 'extension.copart.fees.entry',
            redirect: () => {
              const params = { tab: FEES_URL_PARAM_TABS.general }
              return { name: 'extension.copart.fees', params }
            },
            component: CopartExtensionFees,
            meta: {
              isLoggedInOnly: true,
              claim: USER_CLAIMS.EXTENSION_FEES
            },
            children: [
              {
                path: `:tab(${toUrlParams(FEES_URL_PARAM_TABS)})`,
                name: 'extension.copart.fees',
                meta: {
                  isLoggedInOnly: true,
                  claim: USER_CLAIMS.EXTENSION_FEES
                }
              }
            ]
          },
          {
            path: 'dealers/:dealerId?',
            name: 'extension.copart.dealers.entry',
            component: CopartExtensionDealers,
            meta: {
              isLoggedInOnly: true,
              claim: USER_CLAIMS.EXTENSION_DEALERS
            }
          },
          {
            path: 'accounts',
            name: 'extension.copart.accounts.entry',
            component: CopartExtensionAccounts,
            meta: {
              isLoggedInOnly: true,
              claim: USER_CLAIMS.EXTENSION_ACCOUNTS
            }
          },
          {
            path: 'won',
            name: 'extension.copart.won.entry',
            redirect: () => {
              const params = { tab: COPART_WON_URL_PARAM_TABS.won }
              return { name: 'extension.copart.won', params }
            },
            component: CopartExtensionWon,
            meta: {
              isLoggedInOnly: true,
              claim: USER_CLAIMS.EXTENSION_WON
            },
            children: [
              {
                path: `:tab(${toUrlParams(COPART_WON_URL_PARAM_TABS)})`,
                name: 'extension.copart.won',
                meta: {
                  isLoggedInOnly: true,
                  claim: USER_CLAIMS.EXTENSION_WON
                }
              }
            ]
          },
          {
            path: 'analytics',
            name: 'extension.copart.analytics.entry',
            component: CopartExtensionAnalytics,
            meta: {
              isLoggedInOnly: true,
              claim: USER_CLAIMS.EXTENSION_ANALYTICS
            }
          },
          {
            path: 'claims',
            name: 'extension.copart.claims.entry',
            component: CopartExtensionClaims,
            meta: {
              isLoggedInOnly: true,
              claim: USER_CLAIMS.EXTENSION_CLAIMS
            }
          },
          {
            path: 'notifications',
            name: 'extension.copart.notifications.entry',
            component: CopartExtensionNotifications,
            meta: {
              isLoggedInOnly: true,
              claim: USER_CLAIMS.EXTENSION_NOTIFICATIONS
            }
          }
        ]
      },
      {
        path: 'extension/impact',
        name: 'extension.impact',
        component: { render: (h) => h('router-view') },
        redirect: () => {
          if (can(USER_CLAIMS.IMPACT_EXTENSION_DEALERS)) {
            return { name: 'extension.impact.dealers.entry' }
          }

          if (can(USER_CLAIMS.IMPACT_EXTENSION_ACCOUNTS)) {
            return { name: 'extension.impact.accounts.entry' }
          }
        },
        children: [
          {
            path: 'dealers/:dealerId?',
            name: 'extension.impact.dealers.entry',
            component: ImpactExtensionDealers,
            meta: {
              isLoggedInOnly: true,
              claim: USER_CLAIMS.IMPACT_EXTENSION_DEALERS
            }
          },
          {
            path: 'accounts',
            name: 'extension.impact.accounts.entry',
            component: ImpactExtensionAccounts,
            meta: {
              isLoggedInOnly: true,
              claim: USER_CLAIMS.IMPACT_EXTENSION_ACCOUNTS
            }
          },
          {
            path: 'won',
            name: 'extension.impact.won.entry',
            redirect: () => {
              const params = { tab: IMPACT_WON_URL_PARAM_TABS.invoices }
              return { name: 'extension.impact.won', params }
            },
            component: ImpactExtensionWon,
            meta: {
              isLoggedInOnly: true,
              claim: USER_CLAIMS.IMPACT_EXTENSION_WON
            },
            children: [
              {
                path: `:tab(${toUrlParams(IMPACT_WON_URL_PARAM_TABS)})`,
                name: 'extension.impact.won',
                meta: {
                  isLoggedInOnly: true,
                  claim: USER_CLAIMS.IMPACT_EXTENSION_WON
                }
              }
            ]
          }
        ]
      }
    ]
  },
  {
    path: '/login',
    name: 'signIn',
    component: SignIn,
    // Doesn't allow to visit auth page if user is already logged in
    beforeEnter(to, from, next) {
      if (isLoggedIn()) {
        next({ name: 'adminPanel' })
      } else {
        next()
      }
    }
  },
  {
    path: '*',
    name: '404',
    beforeEnter(to, from, next) {
      if (isLoggedIn()) {
        next({ name: 'adminPanel' })
      } else {
        next({ name: 'signIn' })
      }
    }
  }
]

const router = new Router({
  mode: 'history',
  base: process.env.BASE_URL,
  routes
})

router.beforeEach((to, from, next) => {
  const feature = to.meta.feature

  if (!feature || config.featureFlags[feature]) {
    return next()
  }

  next({ path: '/' })
})

router.beforeEach((to, from, next) => {
  const claim = to.meta.claim

  if (!claim || can(claim)) {
    return next()
  }

  next({ path: '/' })
})

router.beforeEach((to, from, next) => {
  if (!isLoggedIn() || !isCarfaxReseller()) {
    return next()
  }

  const acceptedRoutes = ['/services/carfax', '/edit-profile']
  return acceptedRoutes.includes(to.path)
    ? next()
    : next({ path: '/services/carfax' })
})

router.beforeEach((to, from, next) => {
  router.routeFrom = from
  document.body.setAttribute('route', to.name)
  next()
})

router.beforeEach((to, from, next) => {
  const isLoggedInOnly = to.meta.isLoggedInOnly

  if (isLoggedInOnly && !isLoggedIn()) {
    next({ path: '/login' })
  } else {
    next()
  }
})

function toUrlParams(obj) {
  return Object.values(obj).join('|')
}

function isLoggedIn() {
  return rootGet(`entities/user/${userGetters.IS_LOGGED_IN}`)
}

function isCarfaxReseller() {
  return rootGet(`entities/user/${userGetters.IS_CARFAX_RESELLER}`)
}

export default router
